body {
  color: #282c34;
}

main {
  padding: 3rem 2rem;
}

p {
  font-size: calc(10px + 2vmin);
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1.5rem;
}

.result {
  text-align: center;
  color: #fff;
  font-size: calc(16px + 2vmin);
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.result::before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 35px;
  margin-right: 0.5rem;
}

.result.free::before {
  background: #1a8100;
}

.result.free {
  color: #1a8100;
}

.result.full {
  color: #a70000;
}

.result.full::before {
  background: #a70000;
}

.wrap {
  display: flex;
  align-items: center;
}

.button {
  border-radius: 4px;
  padding: 1rem 2rem;
  border: 1px solid #282c34;
  background: #fff;
  appearance: none;
  box-shadow: none;
  font-weight: 800;
  min-width: 100px;
}

.button.button--fill {
  border: 1px solid #282c34;
  background: #282c34;
  color: #fff;
  margin-right: 1rem;
}
